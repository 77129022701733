import React from "react";
import Avatar from "react-avatar";
import { Progress } from "react-sweet-progress";

import PostulationRating from "../rating";
import TogglePinned from "../../toggle_pinned";
import DropDownOptions from "./dropdown_options";

const Rank = ({ postulation, authentificationHeaders }) => {
  const { project, applicant, highlight } = postulation;

  const applicant_name = highlight["applicant.name"]
    ? highlight["applicant.name"]
    : applicant.name;

  return (
    <div className="col-md-4">
      <div className={`kt-portlet kt-portlet--height-fluid border candidates-card card-hover
        ${postulation.discarded_at ? "discarded_postulation" : ""}
        ${postulation.pinned_at ? "pinned-card" : ""}
      `}>
        <div className="toggle-select">
          <label htmlFor="defaultCheck1">
            <input type="checkbox" value="" id="defaultCheck1"/>
          </label>
        </div>
        <TogglePinned
          reload={false}
          current_pinned_at={postulation.pinned_at}
          authentificationHeaders={authentificationHeaders}
          postulation_id={postulation._id}
        />
        <div className="kt-portlet__head kt-portlet__head--noborder p-2">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title"></h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <a className="btn btn-clean btn-sm btn-icon" data-toggle="dropdown">
              <i className="flaticon-more"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <DropDownOptions
                project={project}
                postulation={postulation}
                postulation_id={postulation._id}
                applicant={applicant}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body" style={{ zIndex: 1 }}>
          <div className="kt-widget kt-widget--user-profile-2">
            <div className="kt-widget__head">
              <div className="kt-widget__media">
                <Avatar
                  size="60"
                  round
                  name={applicant.name}
                  email={applicant.email}
                  color="rgba(93, 120, 255, 0.4)"
                />
              </div>

              <div className="kt-widget__info">
                <a
                  href={`/process/${project.id}/applicants/${applicant.id}`}
                  className="kt-widget__username"
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: applicant_name
                  }}
                ></a>
                <span className="kt-widget__desc">
                  <span className="d-md-inline-flex">
                    <PostulationRating
                      total={5}
                      postulation_id={postulation._id}
                      current_rating={postulation.rating}
                      interactive={true}
                    />
                  </span>
                </span>
              </div>
            </div>

            <div className="kt-widget__body">
              <div className="kt-widget__item">
                <div className="kt-widget__contact">
                  <i className="flaticon-email mr-2"></i>
                  <a className="kt-widget__data __force-text_break-word">
                    {applicant.email}
                  </a>
                </div>
                <div className="kt-widget__contact">
                  <i className="flaticon-chat-2 mr-2"></i>
                  <a className="kt-widget__data">{applicant.phone_number}</a>
                </div>
                <div className="kt-widget__contact">
                  <i className="flaticon2-calendar-3 mr-2"></i>
                  <span className="kt-widget__data"> {applicant.rut}</span>
                </div>
              </div>
            </div>

            <div className="kt-widget__footer">
              <div className="kt-widget__item">
                <div className="kt-widget__details w-100">
                  <span className="kt-widget__title text-primary">
                    Puntaje Global
                  </span>
                  <Progress
                    theme={{
                      success: {
                        symbol: postulation.global_score,
                        color: "#5d78ff"
                      }
                    }}
                    percent={postulation.global_score}
                    status="success"
                    strokeWidth={3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rank;
