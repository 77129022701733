import React, { useState, useEffect } from "react";
import { ReactiveBase, ReactiveComponent } from "@appbaseio/reactivesearch";

import { defaultQuery, renderFilters, componentsIds } from "./common_board";
import LineChartWrapper from "./internal_dashboard/wrappers/line_chart";
import DonutChartWrapper from "./internal_dashboard/wrappers/donut_chart";
import HeadersWrapper from "./internal_dashboard/wrappers/headers";

import theme from "./reactive_search/theme";
import CustomSelectedFilters from "./reactive_search/custom_selected_filters";

const InternalDashboard = (props) => {
  const { authentication_headers, is_admin, projects_ids } = props;

  return (
    <ReactiveBase
      app="postulations"
      url={`${window.location.origin}/elastic`}
      theme={theme}
      headers={{
        "Content-Type": "application/json",
        ...authentication_headers,
      }}
      transformRequest={(data) => {
        return { ...data, body: JSON.stringify(data.body) };
      }}
    >
      <ReactiveComponent
        componentId="generalInformation"
        react={{
          and: componentsIds(),
        }}
        defaultQuery={defaultQuery(projects_ids)}
        customQuery={() => ({
          timeout: "10s",
          aggregations: {
            by_project_state: {
              terms: {
                field: "project.state",
              },
            },
            by_shortlist_state: {
              terms: {
                field: "shortlist.status",
              },
            },
            by_zyvo_state: {
              terms: {
                field: "zyvo.status",
              },
            },
            by_interview_state: {
              terms: {
                field: "interview.status",
              },
            },
            avg_shortlist_score: {
              avg: { field: "shortlist.score" },
            },
            avg_zyvo_score: {
              avg: { field: "zyvo.score" },
            },
            postulations_count: {
              value_count: {
                field: "_id",
              },
            },
          },
          size: 0,
        })}
        render={({ aggregations }) => (
          <HeadersWrapper
            aggregations={aggregations}
            aggs={["by_shortlist_state", "by_zyvo_state"]}
          />
        )}
      />

      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div
                className="custom_scroll"
                style={{ height: "1360px", overflowY: "auto" }}
              >
                {renderFilters({ is_admin, projects_ids })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-9">
          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body">
              <CustomSelectedFilters />
            </div>
          </div>

          <div className="kt-portlet kt-portlet--mobile">
            <div className="kt-portlet__body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <ReactiveComponent
                    componentId="averageByPortal"
                    react={{
                      and: componentsIds(),
                    }}
                    defaultQuery={defaultQuery(projects_ids)}
                    customQuery={() => ({
                      timeout: "10s",
                      aggregations: {
                        by_portal: {
                          terms: {
                            field: "portal.name",
                          },
                        },
                      },
                      size: 0,
                    })}
                    render={({ aggregations, loading }) => (
                      <DonutChartWrapper
                        loading={loading}
                        aggregations={aggregations}
                        agg="by_portal"
                        title="Portales"
                      />
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <ReactiveComponent
                    componentId="experienceYears"
                    react={{
                      and: componentsIds(),
                    }}
                    defaultQuery={defaultQuery(projects_ids)}
                    customQuery={() => ({
                      timeout: "10s",
                      aggregations: {
                        by_experience_years: {
                          terms: {
                            field: "experience_years",
                          },
                        },
                      },
                      size: 0,
                    })}
                    render={({ aggregations, loading }) => (
                      <DonutChartWrapper
                        loading={loading}
                        aggregations={aggregations}
                        agg="by_experience_years"
                        title="Años de experiencia"
                      />
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <ReactiveComponent
                    componentId="salaryExpectation"
                    react={{
                      and: componentsIds(),
                    }}
                    defaultQuery={defaultQuery(projects_ids)}
                    customQuery={() => ({
                      timeout: "10s",
                      aggregations: {
                        by_salary_expectation: {
                          terms: {
                            field: "salary_expectation",
                          },
                        },
                      },
                      size: 0,
                    })}
                    render={({ aggregations, loading }) => (
                      <DonutChartWrapper
                        loading={loading}
                        aggregations={aggregations}
                        agg="by_salary_expectation"
                        title="Expectativas de renta"
                      />
                    )}
                  />
                </div>

                <div className="col-sm-12 col-md-6">
                  <ReactiveComponent
                    componentId="averageByGender"
                    react={{
                      and: componentsIds(),
                    }}
                    defaultQuery={defaultQuery(projects_ids)}
                    customQuery={() => ({
                      timeout: "10s",
                      aggregations: {
                        by_gender: {
                          terms: {
                            field: "applicant.gender",
                          },
                        },
                      },
                      size: 0,
                    })}
                    render={({ aggregations, loading }) => (
                      <DonutChartWrapper
                        loading={loading}
                        aggregations={aggregations}
                        agg="by_gender"
                        title="Generos"
                      />
                    )}
                  />
                </div>
                <div className="col-sm-12">
                  <ReactiveComponent
                    componentId="lineChartByCreatedAt"
                    react={{
                      and: componentsIds(),
                    }}
                    defaultQuery={defaultQuery(projects_ids)}
                    customQuery={() => ({
                      timeout: "10s",
                      aggregations: {
                        by_created_at: {
                          date_histogram: {
                            field: "created_at",
                            calendar_interval: "1d",
                          },
                        },
                      },
                      size: 0,
                    })}
                    render={({ aggregations, loading }) => (
                      <LineChartWrapper
                        loading={loading}
                        aggregations={aggregations}
                        chartType={"Area"}
                        title="Cantidad de postulaciones por fecha"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactiveBase>
  );
};

export default InternalDashboard;
