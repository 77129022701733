import React, { Fragment } from "react";
import {
  SingleList,
  MultiList,
  RatingsFilter,
  MultiDropdownList,
  DateRange,
  RangeSlider,
  DataSearch,
} from "@appbaseio/reactivesearch";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import moment from "moment";
import "moment/locale/es";
import { transformedBool, transformedQuery } from "./candidates_list/functions";

const startOfMonth = moment().subtract(12, "months").toDate();
const endOfMonth = moment().toDate();

export const componentsIds = (except = null) => {
  var allIds = [
    "shortlistScore",
    "shortlistState",
    "zyvoScore",
    "zyvoState",
    "InterviewScore",
    "InterviewState",
    "projectUsers",
    "projectSearch",
    "cvScore",
    "globalScore",
    "globalState",
    "portalList",
    "postulationByMonth",
    "areaForHuman",
    "chargeTypeForHuman",
    "genderList",
    "universitiesSearch",
    "ratingList",
    "ownCvList",
    "discardedList",
    "searchPostulation",
    "pinnedList",
  ];
  return _.without(allIds, except);
};

const showDataSearch = ({ withDataSearch, projects_ids }) => {

  if (withDataSearch) {
    return (
      <div className="p-3">
        <DataSearch
          innerClass={{
            input: "form-control",
          }}
          showIcon={true}
          iconPosition={"right"}
          title="Buscar en CVs"
          //defaultQuery={defaultQuery(projects_ids)} // It doesn't work with suggestions.
          /*customQuery={
            (value) => {
              return {
                query: {
                  match_phrase: {
                    "applicant.cv_as_text": value,
                  }
                }
              }
            }
          }*/
          componentId="searchPostulation"
          dataField={[
            "applicant.cv_as_text",
            "applicant.name",
            //"applicant.rut",
            //"applicant.email",
            //"applicant.phone_number",
          ]}
          placeholder="Buscar en CVs"
          highlight={true}
          highlightField="applicant.name"
          autosuggest={true}
          enablePredictiveSuggestions={true}
          render={({ loading, error, data, value, downshiftProps: { isOpen, getItemProps, highlightedIndex } }) => {
            if (loading) {
              return <div>Obteniendo sugerencias.</div>;
            }
            if (error) {
              return <div>Algo sali&oacute; mal! Detalles del error: {JSON.stringify(error)}</div>;
            }
            let highIndexesCustom = [];
            if (data) {
              highIndexesCustom = [];
            }
            return isOpen && Boolean(value.length) ? (
              <div>
                {data.slice(0, 20).map((suggestion, index) => {
                  const firstValue = value.split(' ')[0];
                  const secondValue = value.split(' ')[1];
                  const thirdValue = value.split(' ')[2];
                  const arraySugg = suggestion.value.split(' ');
                  const firstSugg = arraySugg.find(x => x.toLowerCase().includes(firstValue.toLowerCase()));
                  const indexSugg = arraySugg.indexOf(firstSugg);

                  let labelAndValue = "";

                  if (firstSugg && secondValue && thirdValue) {
                    if (arraySugg[indexSugg + 1].toLowerCase() === secondValue.toLowerCase() && arraySugg[indexSugg + 2].toLowerCase() === thirdValue.toLowerCase()) {
                      labelAndValue = `${firstSugg} ${arraySugg[indexSugg + 1] ? arraySugg[indexSugg + 1] : ''} ${arraySugg[indexSugg + 2] ? arraySugg[indexSugg + 2] : ''} ${arraySugg[indexSugg + 3] ? arraySugg[indexSugg + 3] : ''}`;
                      highIndexesCustom.push(index);
                    } else {
                      labelAndValue = null;
                    }
                  } else if (firstSugg && secondValue) {
                    if (arraySugg[indexSugg + 1].toLowerCase() === secondValue.toLowerCase()) {
                      labelAndValue = `${firstSugg} ${arraySugg[indexSugg + 1] ? arraySugg[indexSugg + 1] : ''} ${arraySugg[indexSugg + 2] ? arraySugg[indexSugg + 2] : ''} ${arraySugg[indexSugg + 3] ? arraySugg[indexSugg + 3] : ''}`;
                      highIndexesCustom.push(index);
                    } else {
                      labelAndValue = null;
                    }
                  } else if (firstSugg) {
                    labelAndValue = `${firstSugg} ${arraySugg[indexSugg + 1] ? arraySugg[indexSugg + 1] : ''} ${arraySugg[indexSugg + 2] ? arraySugg[indexSugg + 2] : ''} ${arraySugg[indexSugg + 3] ? arraySugg[indexSugg + 3] : ''}`;
                    highIndexesCustom.push(index);
                  }

                  //console.log(highIndexesCustom);
                  //console.log(highlightedIndex);

                  if (firstSugg) {
                    return (
                      <div key={suggestion.value}
                        style={{
                          background: index === highIndexesCustom[highlightedIndex]  ? '#EDF1FD' : 'transparent',
                          color: index === highIndexesCustom[highlightedIndex] ? '#5d78ff' : 'dimgray'
                        }}
                        {...getItemProps({ item: { label: labelAndValue, value: (highlightedIndex < highIndexesCustom.length) ? labelAndValue : value } })}>
                          {/*suggestion.value*/}
                          {labelAndValue}
                      </div>
                    )
                  }
                }
                )}
                {Boolean(value.length) && (
                  <div {...getItemProps({ item: { label: value, value: value } })}>
                    Sugerencias para "{value}"
                  </div>
                )}
              </div>
            ) : null;
          }}

          fuzziness={2}
          size={20}
          debounce={500}
          queryFormat={'and'}
          react={{
            and: componentsIds("searchPostulation"),
          }}
          filterLabel="Buscar competencia en CV"
        />
      </div>
    );
  }
};

const showUsers = ({ is_admin, projects_ids }) => {
  if (is_admin)
    return (
      <div className="p-3">
        <MultiDropdownList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="projectUsers"
          dataField="project.created_by.name_and_email"
          showSearch={true}
          title="Usuarios"
          sortBy="count"
          showCount={true}
          size={1000}
          placeholder="Buscar Usuarios"
          showFilter={true}
          filterLabel="Usuarios"
          loader="Cargando ..."
          react={{
            and: componentsIds("projectUsers"),
          }}
        />
      </div>
    );
};

export const defaultQuery = (projects_ids) => {
  return () => {
    return {
      timeout: "10s",
      query: {
        bool: {
          must: [{ terms: { "project.id": projects_ids } }],
        },
      },
    };
  };
};

const renderProduct = ({ product, title, projects_ids }) => {
  return (
    <Fragment>
      <div className="p-3">
        <RangeSlider
          componentId={`${product}Score`}
          defaultQuery={defaultQuery(projects_ids)}
          dataField={`${product}.score`}
          title={`Puntaje ${title}`}
          filterLabel={`Puntaje ${title}`}
          range={{
            start: 0,
            end: 100,
          }}
          defaultValue={{
            start: 0,
            end: 100,
          }}
          rangeLabels={{
            start: "0%",
            end: "100%",
          }}
          tooltipTrigger={"hover"}
          stepValue={1}
          showHistogram={true}
          showFilter={true}
          interval={2}
          react={{
            and: componentsIds(`${product}Score`),
          }}
        />
      </div>

      <div className="p-3">
        <MultiList
          componentId={`${product}State`}
          defaultQuery={defaultQuery(projects_ids)}
          dataField={`${product}.status`}
          title={`Estado ${title}`}
          sortBy="asc"
          queryFormat="or"
          selectAllLabel="Todos los Estados"
          showCheckbox={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Estados"
          loader="Cargando ..."
          react={{
            and: componentsIds(`${product}State`),
          }}
        />
      </div>
    </Fragment>
  );
};

export const renderFilters = ({ is_admin, projects_ids, withDataSearch }) => {
  return (
    <Fragment>
      {showUsers({ is_admin, projects_ids })}
      {showDataSearch({ withDataSearch, projects_ids })}

      <div className="p-3">
        <MultiDropdownList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="projectSearch"
          dataField="project.charge_name"
          showSearch={true}
          title="Procesos"
          sortBy="count"
          showCount={true}
          size={1000}
          placeholder="Buscar Procesos"
          showFilter={true}
          filterLabel="Procesos"
          loader="Cargando ..."
          react={{
            and: componentsIds("projectSearch"),
          }}
        />
      </div>

      <div className="p-3">
        <MultiDropdownList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="universitiesSearch"
          dataField="study_data.description"
          showSearch={true}
          title="Estudios"
          sortBy="count"
          showCount={true}
          placeholder="Buscar Estudios"
          showFilter={true}
          filterLabel="Estudios"
          loader="Cargando ..."
          react={{
            and: componentsIds("universitiesSearch"),
          }}
        />
      </div>

      <div className="p-3">
        <DateRange
          defaultQuery={defaultQuery(projects_ids)}
          defaultValue={{
            start: startOfMonth,
            end: endOfMonth,
          }}
          componentId="postulationByMonth"
          dataField="created_at"
          title="Rango de Fechas"
          placeholder={{
            start: "Inicio",
            end: "Termino",
          }}
          numberOfMonths={2}
          queryFormat="date"
          autoFocusEnd={true}
          showClear={true}
          showFilter={true}
          filterLabel="Fecha"
          dayPickerInputProps={{
            formatDate: formatDate,
            parseDate: parseDate,
            format: "DD/MM/YYYY",
            placeholder: `${formatDate(new Date(), "DD/MM/YYYY", "es")}`,
            dayPickerProps: {
              locale: "es",
              localeUtils: MomentLocaleUtils,
            },
          }}
          react={{
            and: componentsIds("postulationByMonth"),
          }}
        />
      </div>

      <div className="p-3">
        <MultiList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="areaForHuman"
          dataField="project.area_for_human"
          title="Áreas"
          sortBy="count"
          queryFormat="or"
          selectAllLabel="Todas las Áreas"
          showCheckbox={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Área"
          loader="Cargando ..."
          react={{
            and: componentsIds("areaForHuman"),
          }}
        />
      </div>

      <div className="p-3">
        <MultiList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="chargeTypeForHuman"
          dataField="project.charge_type_for_human"
          title="Cargos"
          sortBy="count"
          queryFormat="or"
          selectAllLabel="Todos los cargos"
          showCheckbox={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Cargos"
          loader="Cargando ..."
          react={{
            and: componentsIds("chargeTypeForHuman"),
          }}
        />
      </div>

      <div className="p-3">
        <SingleList
          defaultQuery={defaultQuery(projects_ids)}
          componentId="genderList"
          dataField="applicant.gender"
          title="Generos"
          selectAllLabel="Todos los Generos"
          showRadio={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Genero"
          includeNullValues
          loader="Cargando ..."
          react={{
            and: componentsIds("genderList"),
          }}
        />
      </div>

      <div className="p-3">
        <SingleList
          defaultQuery={defaultQuery(projects_ids)}
          customQuery={(value, props) =>
            transformedQuery({ value, field: "discarded" })
          }
          transformData={(list) => transformedBool(list)}
          componentId="discardedList"
          title="Descartado"
          dataField="discarded"
          showRadio={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Descartado"
          includeNullValues
          loader="Cargando ..."
          react={{
            and: componentsIds("discardedList"),
          }}
        />
      </div>

      <div className="p-3">
        <SingleList
          defaultQuery={defaultQuery(projects_ids)}
          customQuery={(value, props) =>
            transformedQuery({ value, field: "pinned" })
          }
          transformData={(list) => transformedBool(list)}
          componentId="pinnedList"
          title="Favoritos"
          dataField="pinned"
          showRadio={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Favoritos"
          loader="Cargando ..."
          react={{
            and: componentsIds("pinnedList"),
          }}
        />
      </div>

      <div className="p-3">
        <SingleList
          defaultQuery={defaultQuery(projects_ids)}
          customQuery={(value, props) =>
            transformedQuery({ value, field: "own_cv" })
          }
          transformData={(list) => transformedBool(list)}
          componentId="ownCvList"
          title="Tiene CV"
          dataField="own_cv"
          showRadio={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Tiene Cv"
          includeNullValues
          loader="Cargando ..."
          react={{
            and: componentsIds("ownCvList"),
          }}
        />
      </div>

      <div className="p-3">
        <MultiList
          className={"multi_list-h"}
          defaultQuery={defaultQuery(projects_ids)}
          componentId="portalList"
          dataField="portal.name"
          title="Portales"
          sortBy="asc"
          queryFormat="or"
          selectAllLabel="Todos los Portales"
          showCheckbox={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Portales"
          loader="Cargando ..."
          react={{
            and: componentsIds("portalList"),
          }}
        />
      </div>

      <div className="p-3">
        <RangeSlider
          componentId="cvScore"
          defaultQuery={defaultQuery(projects_ids)}
          dataField="cv_score"
          title="Match CV"
          filterLabel="Match CV"
          range={{
            start: 0,
            end: 100,
          }}
          defaultValue={{
            start: 0,
            end: 100,
          }}
          rangeLabels={{
            start: "0%",
            end: "100 pts",
          }}
          tooltipTrigger={"hover"}
          stepValue={1}
          showHistogram={true}
          showFilter={true}
          interval={2}
          react={{
            and: componentsIds("cvScore"),
          }}
        />
      </div>

      <div className="p-3">
        <RangeSlider
          componentId="globalScore"
          defaultQuery={defaultQuery(projects_ids)}
          dataField="global_score"
          title="Puntaje Candidatos"
          filterLabel="Puntaje Candidatos"
          range={{
            start: 0,
            end: 100,
          }}
          defaultValue={{
            start: 0,
            end: 100,
          }}
          rangeLabels={{
            start: "0 pts.",
            end: "100 pts",
          }}
          tooltipTrigger={"hover"}
          stepValue={1}
          showHistogram={true}
          showFilter={true}
          interval={2}
          react={{
            and: componentsIds("globalScore"),
          }}
        />
      </div>

      <div className="p-3">
        <MultiList
          componentId="globalState"
          defaultQuery={defaultQuery(projects_ids)}
          dataField="global_state.es"
          title="Estado Candidatos"
          sortBy="asc"
          queryFormat="or"
          selectAllLabel="Todos los Estados"
          showCheckbox={true}
          showCount={true}
          showSearch={false}
          showFilter={true}
          filterLabel="Estados"
          loader="Cargando ..."
          react={{
            and: componentsIds("globalState"),
          }}
        />
      </div>

      <div className="p-3">
        <RatingsFilter
          defaultQuery={defaultQuery(projects_ids)}
          componentId="ratingList"
          dataField="rating"
          title="Estrellas"
          filterLabel="Estrellas"
          data={[
            { start: 4, end: 5, label: "+4" },
            { start: 3, end: 5, label: "+3" },
            { start: 2, end: 5, label: "+2" },
            { start: 1, end: 5, label: "+1" },
          ]}
          includeNullValues
          react={{
            and: componentsIds("ratingList"),
          }}
        />
      </div>

      <hr className="w-100" />

      {renderProduct({
        product: "shortlist",
        title: "Filtro Curricular",
        projects_ids,
      })}
      {renderProduct({
        product: "zyvo",
        title: "Tests",
        projects_ids,
      })}
      {renderProduct({
        product: "interview",
        title: "Entrevista",
        projects_ids,
      })}
    </Fragment>
  );
};
